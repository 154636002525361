import React from 'react'
import Seo from '../../components/Seo'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import BigTitle from '../../components/BigTitle'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserSection from '../../components/TeaserSection'
import IndividualContactSection from '../../components/IndividualContactSection'
import SliderSection from '../../components/SliderSection'
import Slide from '../../components/Slide'
import NavigationLink from '../../components/NavigationLink'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Architekturberatung"
           description="Wir optimieren Ihre Softwareentwicklungsprozesse und begleiten Sie von der Strategie bis zur Umsetzung."
      />
      <BigTitle title="ARCHITEKTURBERATUNG" className="mask-black" imageRelativePath={'Titelbild_Architektur.jpg'}
                textInverted={true} menuInverted={true} logoInverted={true}>
        Von der Strategie bis zur Umsetzung
      </BigTitle>

      <DefaultSection>
        <Article>
          <h2>Wir standardisieren Ihren Softwareentwicklungsprozess.</h2>
          <p>
            Sie verantworten mehrere Software-Projekte, die über mehrjährige Lebenszyklen hinweg durch verschiedene
            Teams entwickelt werden? In Ihrem Bereich existieren Teams, die zeitgleich verschiedene Projekte betreuen?
            Oder Entwicklungsleistungen werden durch externe Zulieferer und Dienstleister erbracht und anschließend
            durch interne Teams übernommen?
          </p>
          <p>Was ist in allen drei Fällen gleich?
            Teams sind <b>Systemen konfrontiert, die jeweils anders aussehen.</b> Wenn ein Team zwischen Projekten
            wechselt oder ein Projekt zwischen mehreren Teams, trifft es jedes Mal auf eine andere Struktur, eine andere
            Architektur. Das erzeugt Reibungsverluste, Risiken und Aufwände. <b>Und das wiederum kostet Zeit, Geld und
              Nerven.</b>
          </p>
          <h3>Unser Vorgehen</h3>
          <p>Wir analysieren und optimieren Ihre Entwicklungsprozesse und vereinheitlichen diese. Wir legen dabei hohen
            Wert darauf, eine ausgewogene Balance zwischen Standardisierung und Flexibilität zu finden, um notwendige
            Individualität zu sichern. <b>Sie können so mehrere Projekte im Rahmen einer Systemlandschaft betreuen, die
              sich durch eine einheitliche Grundstruktur sowie Architektur-, Technologie-, Vorgehensstandards
              auszeichnen.</b> Sie können Projekte zwischen Teams oder Teams zwischen Projekten wechseln und dabei
            Reibungsverluste minimieren. Das spart Kosten und Zeit.</p>
          <h3>Unsere Leistungen</h3>
          <ul>
            <li>Review und Erstellung organisationsweiter Technologie- und Architekturrichtlinien</li>
            <li>Entwicklungsbegleitende automatisierte Architekturanalysen zur Qualitätssicherung</li>
            <li>Werkzeugbasierte Unterstützung von Abnahmeprozessen, u. a. mit jQAssistant</li>
            <li>Architekturbewertung bestehender Systeme</li>
            <li>Allgemeine und kundenspezifische Anpassungen von jQAssistant für individuelle und erweiterte
              Architekturanalysen
            </li>
          </ul>
          <div className="btns-action">
            <NavigationLink to="#kontakt">Wie können wir Ihnen helfen?</NavigationLink>
          </div>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-gradient-orange">
        <TeaserHeader title="jQAssistant" linkTo={'/jqassistant/'} linkTitle={'Warum jQAssistant'}>
          <p className="mb-5">jQAssistant ist ein Open-Source-Tool, das Ihnen hilft, die Qualität Ihrer Softwaresysteme zu analysieren und zu kontrollieren.
          </p>
        </TeaserHeader>
      </TeaserSection>

      <DefaultSection className="bg-level-1">
        <Article>
          <h2>Wir modernisieren Ihre Systemlandschaft mit Blick in die Zukunft. </h2>
          <p>
            Ihre Systemlandschaft ist in die Jahre gekommen und benötigt dringend ein Update? Nur leider ist keiner der
            ursprünglichen Entwickler noch im Unternehmen. Von den „Neuen“ traut sich keiner ran. Probleme fangen an,
            sich zu häufen: <b>Bugs, Security-Incidents, Nichtumsetzbarkeit von Features. Die Wartungskosten steigen
            exponentiell</b> und Ihre Nutzer verlieren langsam das Vertrauen. Und nun?
          </p>
          <h3>Unser Vorgehen</h3>
          <p>Wir analysieren Ihre Systemlandschaft und entwickeln darauf basierend Migrationsstrategien mit Blick in die
            Zukunft. <b>Im
              Fokus stehen neben technischen Aspekten insbesondere auch Fragen effizienter Entwicklungsprozesse sowie
              notwendiger Anpassungen von Organisationsstrukturen.</b> Wir begleiten die Umsetzung durch Ihre Teams vom
            Konzept bis zum Code und behalten dabei Ihre kurz- bis langfristigen Geschäftsziele im Blick.</p>
          <div className="btns-action">
            <NavigationLink to="#kontakt">Wie können wir Ihnen helfen?</NavigationLink>
          </div>
        </Article>
      </DefaultSection>

      <SliderSection menuInverted={true} logoInverted={true}>
        <Slide category="Case Study" title="Bundesagentur für Arbeit" keywords="Qualität, Wartbarkeit"
               linkTo="/referenzen/arbeitsagentur/"
               linkTitle="Zur Referenz" className="bg-gradient-brand-2">
          <p>Bei der Bundesagentur für Arbeit ermöglicht unser Open-Source-Tool jQAssistant <strong>kontinuierliche
            Transparenz </strong>
            und Kontrolle über die Architektur komplexer IT-Systeme und somit ihre <strong>langfristige
              Wartbarkeit</strong>.</p>
        </Slide>
        <Slide category="Case Study" title="One Data GmbH" keywords="Architektur, Modernisierung"
               linkTo="/referenzen/one-data/"
               linkTitle="Zur Referenz" className="bg-gradient-brand-3">
          <p>Mit der Hilfe von jQAssistant haben wir für die ONE DATA Plattform der One Data GmbH eine <strong>moderne
            Softwarearchitektur</strong> entworfen, sowie den <strong>Prozess der Modernisierung</strong> geplant und
            begleitet.</p>
        </Slide>
        <Slide category="Case Study" title="E-Post Development GmbH" keywords="Architektur, Qualität"
               linkTo="/referenzen/epost-jqa/" linkTitle="Zur Referenz" className="bg-gradient-brand-1">
          <p>
            Unser Open-Source-Framework jQAssistant hilft dabei, die Qualität der Microservice Architektur der
            E-Post Development GmbH abzusichern. Lesen Sie mehr in der
            Case-Study: <strong>Erhöhte Softwarequalität und Effizienz durch kontinuierliche Architekturvalidierung bei
            der E-Post Development
            GmbH</strong>
          </p>
        </Slide>
      </SliderSection>

      <DefaultSection>
        <Article>
          <h2>Wir skalieren Ihr Projekt ohne Qualitätsverluste. </h2>
          <p>
            Ihr Projekt läuft super, immer mehr Kunden wollen es, immer mehr Funktionalitäten sollen implementiert
            werden. <b>Leider gerät dabei die Qualität der Software in Gefahr:</b> die Umsetzungsaufwände steigen und
            gleichzeitig häufen sich die Fehlerberichte von Nutzern. Und nun?
          </p>
          <h3>Unser Vorgehen</h3>
          <p>Wir analysieren Ihr Projekt und schauen, was hinter den Qualitätsproblemen steckt: Was sind die Ursachen
            und welche Maßnahmen können die Probleme bekämpfen und vermeiden? Wir orientieren uns dabei an den kurz-,
            mittel- und langfristigen Geschäftszielen und erstellen gemeinsam mit Ihnen die passenden
            Priorisierungen. <b>Die Wirksamkeit der Maßnahmen wird transparent gemacht und regelmäßig neu
              bewertet.</b> Wir begleiten Ihre Teams natürlich tatkräftig bei der Umsetzung – von der Erstellung von
            Konzepten, über Coaching bis hin zum Code.</p>

        </Article>
      </DefaultSection>

      <TeaserSection anchor="kontakt" className="bg-level-1" >
        <Article>
          <h2>Wie können wir Ihnen helfen?</h2>
          <p>Melden Sie sich mit Ihrem Anliegen gerne bei uns per Telefon oder E-Mail. Wir vereinbaren dann ein telefonisches Erstgespräch, in dem wir offen über alle Fragen sprechen können und auf dessen Basis ein individuelles Angebot erstellt werden kann.</p>
        </Article>
      </TeaserSection>
      <IndividualContactSection personKey={'dirk'} />
    </React.Fragment>
  )
}

export default Page
